export class SearchEngine {
  /**
   * @roadmap We can implement nested object field support
   * @param list
   * @param fields
   * @param word
   */
  searchInArray(list: any[], fields: string[], word: string) {
    return list.filter(item => {
      return fields.some(field => {
        const safetyValue = item[field] ? item[field].toString().toLowerCase() : '';

        return safetyValue.includes(word);
      })
    })
  }
}